import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'

const Innkjopsavtaler = props => {
  const { instance, lang } = props.pageContext
  const title = `Besparelse på innkjøpsavtaler | ${instance.title}`
  const description =
    'Cost Partner fremforhandler gode innkjøpsavtaler for din bedrift.'

  return (
    <Layout {...props}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div className="main-content content-module">
        <Container textWidth>
          <h1>Innkjøpsavtaler</h1>
          <p>
            <strong>
              Ønsker du bedre kostnadsbesparelse for bedriften din? Da kan det å
              forhandle frem gode innkjøpsavtaler være veien å gå. Det kan vi i
              Cost Partner bistå din bedrift med. Ta gjerne kontakt for en
              uforpliktende samtale!
            </strong>
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>

          <h2>Hvordan spare kostnader på innkjøpsavtaler</h2>
          <p>
            Innkjøpsavtaler som koster unødvendig mye kan være vanskelige å
            komme unna når man er avhengig av produktet eller tjenesten. Det
            finnes likevel måter å forhandle avtalene på, og ved å la noen
            profesjonelle gjøre dette for deg, vil du kunne lykkes.
          </p>
          <p>
            Dette er noe vi i Cost Partner kan hjelpe deg med. Vi er opptatt av
            kostnadsbesparelser, og at våre partnere skal spare så mye som
            mulig. Dersom vi ikke kommer frem til en bedre avtale, tar vi heller
            ikke noe honorar for det.
          </p>

          <h2>Hvordan få gode innkjøpsavtaler</h2>
          <p>
            Det å forhandle frem gode avtaler - uansett om det gjelder innkjøp
            eller annet, krever en god strategi, og i noen tilfeller også det
            rette nettverket.
          </p>
          <p>
            Ifølge Anskaffelser.no, kan virksomheter trolig spare minst{' '}
            <a
              href="https://www.anskaffelser.no/innkjopsledelse/effektivisering"
              target="_blank"
              rel="noopener noreferrer"
            >
              3-6% på innkjøpsvolum ved å systematisk effektivisere
              anskaffelser.
            </a>{' '}
            Dette er fordi innkjøp tar både tid og koster mye. Ved å
            effektivisere disse prosessene, som det å skaffe en partner til å
            gjøre denne jobben for virksomheten, kan bedriften spare mye penger.
          </p>
          <p>
            For å effektivisere innkjøpsprosessene, må man se på alle utgifter
            knyttet til innkjøpsprosessene. Dette er noe vi i Cost Partner kan
            bistå deg med. Ta gjerne kontakt for en uforpliktende prat om
            hvordan vi kan bistå din virksomhet.
          </p>

          <h2>Ulike kostnadskategorier</h2>
          <p>
            Vi analyserer og vurderer besparelser i ulike kostnadskategorier.
          </p>
          <p>
            Den gjennomsnittlige besparelsen ligger på ca 30% hos våre kunder.
            Og det beste av alt er at dette oppnås kostnads- og risikofritt for
            kunden, fordi vi kun beregner honorar når besparelse oppnås.
          </p>
          <p>Vi utfører blant annet analyser innen kostnadskategorier som:</p>
          <ul>
            <li>
              Facility services: Renhold, kantinedrift, drikkeautomater,
              renovasjon, sikkerhet, energi, serviceavtaler
            </li>
            <li>
              Forbruksmateriell: Kontorrekvisita, datarekvisita,
              driftsrekvisita, trykksaker, emballasje, arbeidstøy- og vern,
              industrirekvisita
            </li>
            <li>Tele og IT: Telefon, kontormaskiner</li>
            <li>Transport: Bil, tog, sjøfrakt, flyfrakt</li>
            <li>Annet: Billeasing, banktjenester</li>
          </ul>
          <p>
            Les mer:{' '}
            <Link to={`/${instance.id}/${lang}/kostnadsbesparelser/`}>
              Kostnadsbesparelser
            </Link>
          </p>

          <h2>Hvorfor la Cost Partner være din partner?</h2>
          <p>
            Cost Partner AS er din partner i kostnadsbesparelse og
            fremforhandling av gode innkjøpsavtaler. Vi kjenner markedet godt,
            og har et bredt nettverk. Derfor kan vi skaffe din bedrift de beste
            avtalene.
          </p>
          <p>
            Vi er et konsulentselskap som jobber med besparelser på alle
            indirekte og administrative kostnader.
          </p>
          <p>
            En omfattende analyseprosess og vår unike database med blant annet
            produkter, priser og leverandørinformasjon, samt et betydelig
            innkjøpsvolum, gir besparelser som selskaper ikke kan oppnå på egen
            hånd. Derfor vil det lønne seg å la oss være din partner.
          </p>
          <h3>No cure - no pay</h3>
          <p>
            Vi tar ikke honorar dersom vi ikke oppnår besparelse. Vi kaller
            dette for no cure, no pay. Kunden skal komme til oss og være trygg
            på at vi finner frem til økonomiske løsninger og besparelser.
          </p>

          <h2>Ta kontakt med oss i dag</h2>
          <p>
            Vi har en lang liste med fornøyde kunder som har hatt svært høy
            besparelse ved å bruke oss som forhandlere av innkjøpsavtaler. Etter
            lang tid i bransjen har vi kontroll på hvor de mest gunstige dealene
            finnes, hvordan du kan spare mest og likevel få kvalitet for
            pengene.
          </p>
          <p>
            <strong>
              Høres dette ut som noe for din bedrift? Ta kontakt med Cost
              Partner Norge AS i dag for mer informasjon om hvordan du kan oppnå
              effektiv kostnadsbesparelse.
            </strong>
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>
        </Container>
      </div>
    </Layout>
  )
}

export default Innkjopsavtaler
